export const environment = {
  production: false,
  apiURL: 'https://api.okindia.com/',
  localStorageKeys: {
    user: 'user',
    superCart: 'superCart',
  },
  domainUrl: 'https://ok-india.web.app/',
  razorpayConfig: {
    apiKey: 'rzp_test_4GqlNmmClayLfg',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCaA0EMLavjnQCx_KW_-fq7gbLHXK8QUwg',
    authDomain: 'ok-india.firebaseapp.com',
    projectId: 'ok-india',
    storageBucket: 'ok-india.appspot.com',
    messagingSenderId: '929515720947',
    appId: '1:929515720947:web:4a7859d879ef06100a6786',
    measurementId: 'G-WTLNHS0T0T',
  },
  cleverTap: {
    debugLevel: 1
  },
  appVersion: '2.3.9'
};
